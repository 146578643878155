import {
  AnchorRouter,
  Chip,
  Colours,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuRadioGroup,
  DropdownMenuRadioGroupItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
  EmergencySavingsLogo,
  FynbosLogo,
  Icon,
  MetaPixel,
  PopoverButton,
  Router,
  SavingsPotsLogo,
  TFSALogo
} from '@/Components'
import { cn, useToast } from '@/Lib'
import { PageProps } from '@/types'
import { Head, InertiaLinkProps, Link, router, usePage } from '@inertiajs/react'
import * as ScrollArea from '@radix-ui/react-scroll-area'
import * as Separator from '@radix-ui/react-separator'
import { motion, useSpring, useTransform } from 'framer-motion'
import { posthog } from 'posthog-js'
import {
  FC,
  ReactElement,
  ReactNode,
  forwardRef,
  useCallback,
  useEffect,
  useMemo,
  type ButtonHTMLAttributes,
  type RefAttributes
} from 'react'
import { Toaster } from 'sonner'
import { NavDrawer } from './NavDrawer'

const VITE_APP_URL = import.meta.env.VITE_APP_URL || 'https://fynbos.money'

type AppLayoutProps = {
  title?: string
  actions?: ReactNode
  children?: ReactElement
}

export const AppLayout: FC<AppLayoutProps> = ({ title, actions, children }) => {
  const { user, is_impersonating } = usePage<PageProps>().props
  useToast()

  const _handle = () => {
    router.post(
      route('toggle-hide-amounts'),
      {},
      {
        preserveScroll: true
      }
    )
  }

  useEffect(() => {
    // dont track impersonation
    if (!is_impersonating) {
      posthog.identify(user.id.toString(), { name: user.name })
    }
  }, [user.id, user.name, is_impersonating])

  return (
    <>
      <Head title={title} />
      <div className='relative inset-0 flex min-h-dvh w-full flex-col bg-app lg:flex-row'>
        <nav className='hidden h-full w-72 lg:fixed lg:flex lg:flex-col'>
          {is_impersonating && (
            <div className='m-4 flex items-center space-x-2 rounded border-l-4 border-red-500 bg-red-50 p-3'>
              <span className='text-sm font-medium text-red-800'>
                Currently Impersonating {user.name}
              </span>
              <a
                href={route('stop-impersonation')}
                className='ml-auto text-xs text-blue-600 hover:underline'
              >
                Stop
              </a>
            </div>
          )}
          <div className='m-4 flex items-center justify-between gap-6'>
            <Link href='/' className='mr-auto'>
              <FynbosLogo />
            </Link>
            <HideAmountButton onClick={_handle} />
            <AccountSwitcher />
          </div>

          <ScrollArea.Root className='h-full w-full overflow-hidden'>
            <ScrollArea.Viewport className='h-full w-full'>
              <div className='flex max-w-72 flex-col gap-1 p-4'>
                <NavItem
                  href={route('move-money.index')}
                  className='mb-2 rounded-2xl bg-primary py-4 text-white hover:bg-blue-400'
                >
                  <Icon>savings</Icon>Move money
                </NavItem>
                <NavItem href={route('home')}>
                  <Icon>home</Icon>Home
                </NavItem>
                <NavItem href={route('emergency-savings.index')}>
                  <EmergencySavingsLogo className='p-0.5' />
                  Emergency savings
                </NavItem>
                <NavItem href={route('tfsa.index')}>
                  <TFSALogo className='p-0.5' />
                  Tax-free investments
                </NavItem>
                <NavItem href={route('savings-pots.index')}>
                  <SavingsPotsLogo className='p-0.5' />
                  Savings pots
                  <Chip colour={Colours.purple} className='ml-auto'>
                    New
                  </Chip>
                </NavItem>
                <NavItem href={route('transactions.index')}>
                  <Icon>receipt</Icon>Transactions
                  {/*TODO Figure out a way to have notifications here */}
                  {/*<span className='absolute right-2 top-2 size-2 rounded-full bg-red-500' />*/}
                </NavItem>
                <NavItem href={route('automation-rules.index')}>
                  <Icon>automation</Icon>Automation rules
                </NavItem>
                <NavItem href={route('settings.index')}>
                  <Icon>settings</Icon>Settings
                </NavItem>
              </div>
            </ScrollArea.Viewport>
            <ScrollArea.Scrollbar
              className='flex touch-none select-none p-0.5 transition-colors duration-[160ms] ease-out hover:bg-slate-50 data-[orientation=horizontal]:h-2.5 data-[orientation=vertical]:w-2.5 data-[orientation=horizontal]:flex-col'
              orientation='vertical'
            >
              <ScrollArea.Thumb className="relative flex-1 rounded-[10px] bg-slate-300 before:absolute before:left-1/2 before:top-1/2 before:h-full before:min-h-[44px] before:w-full before:min-w-[44px] before:-translate-x-1/2 before:-translate-y-1/2 before:content-['']" />
            </ScrollArea.Scrollbar>
          </ScrollArea.Root>
          <div className='flex flex-col gap-1 p-4'>
            <NavItem href={route('learn')}>
              <Icon>local_library</Icon>Learn
            </NavItem>
            <AnchorRouter
              target='_blank'
              href='https://wa.me/27686244900'
              className='relative flex w-full items-center gap-2 truncate rounded-xl p-3 font-normal text-medium hover:bg-nav focus-visible:outline focus-visible:outline-2 focus-visible:outline-focus'
            >
              <Icon>support</Icon>Contact support
            </AnchorRouter>
          </div>
        </nav>
        <header className='sticky top-0 z-40 flex w-full border-b border-slate-200 bg-white/95 lg:hidden [@supports(backdrop-filter:blur(0))]:bg-white/80 [@supports(backdrop-filter:blur(0))]:backdrop-blur'>
          <div className='mx-auto flex w-full items-center gap-x-4 p-4 md:max-w-4xl'>
            <NavDrawer>
              <NavItem href={route('home')}>
                <Icon>home</Icon>Home
              </NavItem>
              <NavItem href={route('emergency-savings.index')}>
                <EmergencySavingsLogo className='p-0.5' />
                Emergency savings
              </NavItem>
              <NavItem href={route('tfsa.index')}>
                <TFSALogo className='p-0.5' />
                Tax-free investments
              </NavItem>
              <NavItem href={route('savings-pots.index')}>
                <SavingsPotsLogo className='p-0.5' />
                Savings pots
                <Chip colour={Colours.purple} className='ml-auto'>
                  New
                </Chip>
              </NavItem>
              <NavItem href={route('transactions.index')}>
                <Icon>receipt</Icon>Transactions
              </NavItem>
              <NavItem href={route('automation-rules.index')}>
                <Icon>automation</Icon>Automation rules
              </NavItem>
              <NavItem href={route('settings.index')}>
                <Icon>settings</Icon>Settings
              </NavItem>
              <Separator.Root decorative className='h-px w-full bg-slate-200' />
              <NavItem href={route('learn')}>
                <Icon>local_library</Icon>Learn
              </NavItem>
              <AnchorRouter
                target='_blank'
                href='https://wa.me/27686244900'
                className='relative flex w-full items-center gap-2 truncate rounded-xl p-3 font-normal text-medium hover:bg-nav focus-visible:outline focus-visible:outline-2 focus-visible:outline-focus'
              >
                <Icon>support</Icon>Contact support
              </AnchorRouter>
            </NavDrawer>
            <div className='flex w-full items-center gap-6'>
              <Link href='/' className='mr-auto'>
                <FynbosLogo />
              </Link>
              <HideAmountButton onClick={_handle} />
              <AccountSwitcher />
            </div>
          </div>
        </header>
        <div className='flex min-h-dvh w-full flex-col gap-y-6 px-4 lg:pl-[19rem]'>
          <main className='mx-auto min-h-lvh w-full pt-4 lg:max-w-4xl lg:pt-20'>
            {(title || actions) && (
              <div className='z-20 flex w-full bg-app p-4 lg:sticky lg:top-0'>
                <h1 className='text-xl font-medium text-strong'>{title}</h1>
                <div className='ml-auto'>{actions}</div>
              </div>
            )}
            {children}
          </main>
          <footer className='mx-auto mt-auto flex w-full flex-col space-y-2 py-20 text-xs text-medium lg:max-w-4xl'>
            <p>&copy; {new Date().getFullYear()} Fynbos Inc</p>
          </footer>
        </div>
      </div>
      <Toaster expand position='top-right' offset={16} />
      <FAB href={route('move-money.index')}>
        <Icon>savings</Icon>Move money
      </FAB>
      <MetaPixel />
    </>
  )
}

type NavItemProps = {
  children?: ReactNode
  className?: string
  href: string
}

const NavItem: FC<NavItemProps> = ({ children, href, className }) => {
  const { url } = usePage()

  const isActive = useMemo(() => {
    const active = new URL(url, VITE_APP_URL)
    const current = new URL(href, VITE_APP_URL)

    return active.pathname.startsWith(current.pathname)
  }, [url, href])

  return (
    <Router
      href={href}
      className={cn(
        isActive ? 'bg-nav-active' : 'hover:bg-nav',
        'relative flex w-full items-center gap-2 truncate rounded-xl p-3 text-medium focus-visible:outline focus-visible:outline-2 focus-visible:outline-focus',
        className
      )}
    >
      {children}
    </Router>
  )
}

NavItem.displayName = 'ListItem'

const AccountSwitcher: FC = () => {
  const { user, profiles } = usePage<PageProps>().props

  const _onSelectProfile = useCallback<(value: string) => void>((profile) => {
    router.post(
      route('switch-profile.store'),
      {
        user_sqid: profile
      },
      {
        preserveScroll: true
      }
    )
  }, [])

  const _handleLogout = () => {
    router.post(
      '/logout',
      {},
      {
        onSuccess: () => {
          posthog.reset()
        }
      }
    )
  }

  const profileColour: string = useMemo(() => {
    switch (user.profile_colour) {
      case Colours.rose:
        return 'bg-rose-500'
      case Colours.orange:
        return 'bg-orange-500'
      case Colours.yellow:
        return 'bg-yellow-500'
      case Colours.green:
        return 'bg-green-500'
      case Colours.sky:
        return 'bg-sky-500'
      case Colours.blue:
        return 'bg-blue-500'
      case Colours.indigo:
        return 'bg-indigo-500'
      case Colours.purple:
        return 'bg-purple-500'
      default:
        return 'bg-slate-500'
    }
  }, [user.profile_colour])

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <button className='-m-3 flex h-max w-max rounded-2xl p-3 text-medium hover:bg-nav focus-visible:outline focus-visible:outline-2 focus-visible:-outline-offset-4 focus-visible:outline-blue-500 active:ring-blue-400 disabled:cursor-not-allowed disabled:text-disabled data-[state=open]:bg-nav-active'>
          <div
            className={cn(
              'flex size-6 items-center justify-center rounded-full transition-colors',
              profileColour
            )}
          >
            <span className='text-xs font-medium capitalize leading-none text-white'>
              {user.name.charAt(0)}
            </span>
          </div>
        </button>
      </DropdownMenuTrigger>
      <DropdownMenuContent
        align='end'
        collisionPadding={8}
        className='z-50 flex flex-col gap-0'
      >
        <DropdownMenuRadioGroup
          value={user.sqid}
          onValueChange={_onSelectProfile}
        >
          {profiles.map((profile) => (
            <DropdownMenuRadioGroupItem
              key={profile.sqid}
              value={profile.sqid}
              className={cn(
                profile.profile_colour == Colours.rose && 'hover:bg-rose-50',
                profile.profile_colour == Colours.orange &&
                  'hover:bg-orange-50',
                profile.profile_colour == Colours.yellow &&
                  'hover:bg-yellow-50',
                profile.profile_colour == Colours.green && 'hover:bg-green-50',
                profile.profile_colour == Colours.sky && 'hover:bg-sky-50',
                profile.profile_colour == Colours.blue && 'hover:bg-blue-50',
                profile.profile_colour == Colours.indigo &&
                  'hover:bg-indigo-50',
                profile.profile_colour == Colours.purple &&
                  'hover:bg-purple-50',
                profile.profile_colour == Colours.slate && 'hover:bg-slate-50'
              )}
            >
              <div
                className={cn(
                  'flex size-6 items-center justify-center rounded-full',
                  profile.profile_colour == Colours.rose && 'bg-rose-500',
                  profile.profile_colour == Colours.orange && 'bg-orange-500',
                  profile.profile_colour == Colours.yellow && 'bg-yellow-500',
                  profile.profile_colour == Colours.green && 'bg-green-500',
                  profile.profile_colour == Colours.sky && 'bg-sky-500',
                  profile.profile_colour == Colours.blue && 'bg-blue-500',
                  profile.profile_colour == Colours.indigo && 'bg-indigo-500',
                  profile.profile_colour == Colours.purple && 'bg-purple-500',
                  profile.profile_colour == Colours.slate && 'bg-slate-500'
                )}
              >
                <span className='text-xs font-medium capitalize leading-none text-white'>
                  {profile.name.charAt(0)}
                </span>
              </div>
              {profile.name}
            </DropdownMenuRadioGroupItem>
          ))}
        </DropdownMenuRadioGroup>

        <DropdownMenuSeparator className='mx-1 my-2 h-px bg-slate-200' />
        <PopoverButton
          onClick={_handleLogout}
          className='flex items-center gap-2 hover:bg-red-50'
        >
          <Icon>logout</Icon>Log out
        </PopoverButton>
      </DropdownMenuContent>
    </DropdownMenu>
  )
}

AccountSwitcher.displayName = 'AccountSwitcher'

const FAB = forwardRef<
  HTMLAnchorElement,
  InertiaLinkProps & RefAttributes<HTMLAnchorElement>
>(({ className, children, ...props }, ref) => {
  const { url } = usePage()

  const shouldHide = useMemo(() => {
    const active = new URL(url, VITE_APP_URL)

    return active.pathname.startsWith('/add-money')
  }, [url])

  // TODO We should animate this in and out

  if (shouldHide) return null

  return (
    <Link
      ref={ref}
      className={cn(
        'fixed bottom-4 right-4 z-40 flex items-center justify-center gap-2 rounded-2xl bg-primary p-4 text-white shadow-lg focus-visible:outline focus-visible:outline-2 focus-visible:outline-focus lg:hidden',
        className
      )}
      {...props}
    >
      {children}
    </Link>
  )
})

FAB.displayName = 'FAB'

const HideAmountButton = forwardRef<
  HTMLButtonElement,
  ButtonHTMLAttributes<HTMLButtonElement>
>(({ className, ...buttonProps }, ref) => {
  // The length of the stroke that animates across the visibility icon
  const strokeLength = 26
  const { hide_amounts } = usePage<PageProps>().props

  const spring = useSpring(strokeLength, { duration: 300, bounce: 0 })
  const primaryStrokeDashoffset = useTransform(
    spring,
    (current) => strokeLength - current
  )

  useEffect(() => {
    spring.set(hide_amounts ? strokeLength : 0)
  }, [hide_amounts, spring])

  return (
    <button
      aria-label='Hide amounts button'
      ref={ref}
      {...buttonProps}
      disabled={buttonProps.disabled}
      className={cn(
        'group -m-3 flex h-max w-max rounded-2xl p-3 text-medium hover:bg-nav focus-visible:outline focus-visible:outline-2 focus-visible:-outline-offset-4 focus-visible:outline-blue-500 active:ring-blue-400 disabled:cursor-not-allowed disabled:text-disabled',
        className
      )}
    >
      <svg
        width='24'
        height='24'
        viewBox='0 0 24 24'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        {/* Visibility */}
        <path
          d='M12 16C13.25 16 14.3125 15.5625 15.1875 14.6875C16.0625 13.8125 16.5 12.75 16.5 11.5C16.5 10.25 16.0625 9.1875 15.1875 8.3125C14.3125 7.4375 13.25 7 12 7C10.75 7 9.6875 7.4375 8.8125 8.3125C7.9375 9.1875 7.5 10.25 7.5 11.5C7.5 12.75 7.9375 13.8125 8.8125 14.6875C9.6875 15.5625 10.75 16 12 16ZM12 14.2C11.25 14.2 10.6125 13.9375 10.0875 13.4125C9.5625 12.8875 9.3 12.25 9.3 11.5C9.3 10.75 9.5625 10.1125 10.0875 9.5875C10.6125 9.0625 11.25 8.8 12 8.8C12.75 8.8 13.3875 9.0625 13.9125 9.5875C14.4375 10.1125 14.7 10.75 14.7 11.5C14.7 12.25 14.4375 12.8875 13.9125 13.4125C13.3875 13.9375 12.75 14.2 12 14.2ZM12 19C9.56667 19 7.35 18.3208 5.35 16.9625C3.35 15.6042 1.9 13.7833 1 11.5C1.9 9.21667 3.35 7.39583 5.35 6.0375C7.35 4.67917 9.56667 4 12 4C14.4333 4 16.65 4.67917 18.65 6.0375C20.65 7.39583 22.1 9.21667 23 11.5C22.1 13.7833 20.65 15.6042 18.65 16.9625C16.65 18.3208 14.4333 19 12 19ZM12 17C13.8833 17 15.6125 16.5042 17.1875 15.5125C18.7625 14.5208 19.9667 13.1833 20.8 11.5C19.9667 9.81667 18.7625 8.47917 17.1875 7.4875C15.6125 6.49583 13.8833 6 12 6C10.1167 6 8.3875 6.49583 6.8125 7.4875C5.2375 8.47917 4.03333 9.81667 3.2 11.5C4.03333 13.1833 5.2375 14.5208 6.8125 15.5125C8.3875 16.5042 10.1167 17 12 17Z'
          className='fill-current'
        />
        {/* Visibility dark line */}
        <motion.path
          d='M2.07812 3.49756L20.4629 21.8823'
          strokeWidth='2'
          strokeDasharray={strokeLength}
          strokeDashoffset={primaryStrokeDashoffset}
          stroke='currentColor'
          fill='transparent'
        />
        {/* Visibility negative line */}
        <motion.path
          d='M3.47806 2.11765L21.8629 20.5026'
          strokeWidth='2'
          strokeDasharray={strokeLength}
          strokeDashoffset={primaryStrokeDashoffset}
          className='fill-transparent stroke-white group-hover:stroke-slate-100 lg:stroke-slate-50'
        />
        {/* Eye fill */}
        <motion.path
          d='M8.8999 11.3999L9.0999 12.2999C9.1999 12.7999 9.4999 13.2999 9.8999 13.5999C10.2999 13.9999 10.6999 14.1999 11.1999 14.3999L12.0999 14.5999L8.8999 11.3999Z'
          className={cn(
            hide_amounts ? 'fill-current' : 'fill-transparent',
            'transition-colors duration-300'
          )}
        />
      </svg>
    </button>
  )
})

HideAmountButton.displayName = 'HideAmountButton'
